
import {Directive,HostListener} from '@angular/core';
import { BaiduAnalyticsService } from '../services/baiduAnalytics.service';
/*
百度统计
示列：<a  baiduAnalytics category="首页" action="快游戏" opt_label="" pageUrl="/quickGame" target="_blank" rel="noopener">快游戏</a>
注意：放在文本节点DOM
 */
@Directive({
  selector: '[baiduAnalytics]'
})
export class BaiduAnalyticsDirective {
  constructor(
    private baiduAnalyticsService: BaiduAnalyticsService
  ) { }
  @HostListener("click", ["$event.target"])
  clickFun(target) {
    if(target){
      target.getAttribute('pageUrl') && this.trackPageview(target.getAttribute('pageUrl'));
      target.getAttribute('category') && target.getAttribute('action') && this.trackEvent(
        target.getAttribute('category'),
        target.getAttribute('action'),
        target.getAttribute('opt_label')?target.getAttribute('opt_label'):"-",
        target.getAttribute('opt_value')?target.getAttribute('opt_value'):""
      );
    }
  }
  trackEvent(
    category: string,
    action: string,
    opt_label?:string,
    opt_value?: number
  ) {
    this.baiduAnalyticsService.trackEvent(category, action,opt_label,opt_value);
  }
  trackPageview(
    pageUrl: string,
  ) {
    this.baiduAnalyticsService.trackPageview(pageUrl);
  }

}
