import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../dataClass/User.class';
import { Md5 } from "ts-md5";
/**
 * 用户信息相关服务
 */
@Injectable()
export class UserService {
  public isOpenWatchApp:boolean = false;//是否开启手表业务
  public user: User = null;//用户资料
  constructor(
    private httpClient: HttpClient
  ) {
    
  }
  /**
   * 登录
   * @param email
   * @param password
   */
  loggin(
    email: string,
    password: string,
    verificationCode: string
  ): Observable<User> {
    return Observable.create((observer: Observer<any>) => {
      this.httpClient.post('/api/login/accountLogin', {
        email: email,
        password: Md5.hashStr(password+'lightapp@2017'),
        verificationCode: verificationCode,
      }).subscribe(data => {
        if (data['code'] === 0) {
          this.user = data['data'];
          observer.next(this.user);
        } else {
          observer.error(data);
        }
      });
    });
  }
  /**
   * IDE 跳转登录
   * @param ideToken 身份证明
   * @param sign 签名
   * @param timestamp 时间戳，单位秒
   */
  ideLoggin(
    ideToken: string,
    sign: string,
    timestamp: string
  ): Observable<User> {
    return Observable.create((observer: Observer<any>) => {
      this.httpClient.post('/api/login/loginByIdeToken', {
        ideToken: ideToken,
        sign: sign,
        timestamp: timestamp,
      }).subscribe(data => {
        if (data['code'] === 0) {
          this.user = data['data'];
          observer.next(data);
        } else {
          observer.next(data);
        }
      });
    });
  }

  /**
   * 注销
   */
  logout() {
    return Observable.create((observer: Observer<any>) => {
      this.httpClient.post('/api/login/logout', null)
      .subscribe(data => {
        if (data['code'] === 0) {
          this.user = null;
          localStorage.clear();
          observer.next(data['data']);
          // location.reload()
          //add。退出登录直接跳转到登陆页面。不然会有一个弹窗提示用户未登录。是导航守卫的原因
        } else {
          observer.next(false);
        }
      });
    });
  }
  /**
   * 页面缓存的登录状态查询
   */
  isLoggedIn(): boolean {
    return !!this.user;
  }
  
  /**
   * 从服务器查询当前登录用户账号信息
   */
  getUserInfoRemote() {
    return Observable.create((observer: Observer<any>) => {
      this.httpClient.get('/api/p/account/queryAccountInfo')
        .subscribe(data => {
          if (data['code'] === 0) {
            this.user = data['data'];
            observer.next(data['data']);
          } else {
            observer.next(false);
          }
        });
    });
  }
}
