
import { Validator, FormControl, FormGroup, NG_VALIDATORS } from '@angular/forms';
import { Directive, EventEmitter, Input } from '@angular/core';
@Directive({
    selector:'[imgLength]',
    providers: [{
      provide: NG_VALIDATORS, 
      useExisting: ImgLengthDirective, 
      multi: true
    }],
})
export class ImgLengthDirective implements Validator{
  @Input() count:number;
  @Input() id:string;
  @Input() imgList:Array<any> =[];
  constructor() { }
  validate(group:FormGroup) {
    if (this.imgList.filter(item=>item).length >= this.count) {
      return null;
    }else{
      group['id'] = this.id;//用于滚动到具体位置
      return {'isImg':false};
    }
  }
}