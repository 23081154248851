import { Directive, HostListener, Input } from '@angular/core';
import { Validator, AbstractControl, NgControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[isJstxZh]',
  providers: [{
    provide: NG_VALIDATORS, 
    useExisting: IsJstxZhDirective, 
    multi: true
  }],
})
export class IsJstxZhDirective implements Validator {
  @Input() id:string;
  @Input() compareValue1: string;
  @Input() compareValue2: string;
  constructor() { } 
  validate(control: AbstractControl): any {
    if (control.value) {
      var pattern = /[\u4E00-\u9FA5]/g;
      if(control.value.length<5 || control.value.length>128){
        return { isLength: '不能低于5个字且不能超过128个字' };
      }else if (pattern.test(control.value)) {
        return { isCH: '不可输入中文' };
      }else{
        return true;
      }
    }else{
      return true;
    }
  }
}
