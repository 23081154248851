import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'form-full',
  templateUrl: './formFull.component.html',
  styleUrls: ['./formFull.component.scss'],
  encapsulation: ViewEncapsulation.None,//作用于覆盖视图里的样式
})
export class FormFullComponent implements OnInit {
  @Input() colon: boolean = true;//是否显示冒号
  @Input() title;//标签title
  @Input() sub;//副标签title
  @Input() warn;//警告=* || ''
  constructor() { }

  ngOnInit() {
  }

}
