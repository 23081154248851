import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'form-item',
  templateUrl: './formItem.component.html',
  styleUrls: ['./formItem.component.scss'],
  encapsulation: ViewEncapsulation.None,//作用于覆盖视图里的样式
})
export class FormItemComponent implements OnInit {
  @Input() title;//标签title
  @Input() warn;//警告=* || ''
  constructor() { }

  ngOnInit() {
  }

}
