import { AuthGuard } from '@/app/core/guard/authGuard.service';
import { WhiteListGuard } from '../core/guard/whiteListGuard.service';
import { MembersGuard } from '@/app/core/guard/membersGuard.service';
import { FeatureComponent } from './feature.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { BaiduAnalyticsService } from '@/app/core/services/baiduAnalytics.service';
import 'rxjs/add/operator/filter';
import { UserInfoGuard } from '@/app/core/guard/userInfoGuard.service';
import { LoginGuard } from '@/app/core/guard/loginGuard.service';
import { customCommonModule } from '@/app/core/customCommon.module';
const routes = [
  {
    path: '',
    component: FeatureComponent,
    canActivate: [UserInfoGuard],//先去拿用户信息，canActivate 比 Resolve先执行 
    children: [
      {
        path: '',//快应用首页门户
        loadChildren: './portal/home/home.module#HomeModule',
      },
      {
        path: 'carQuickApp',//车机快应用门户
        loadChildren: './portal/carQuickApp/carQuickApp.module#CarQuickAppModule',
      },
      {
        path: 'watchesQuickApp',//手表快应门户
        loadChildren: './portal/watchesQuickApp/watchesQuickApp.module#WatchesQuickAppModule',//手表快应用展示模块
      },
      {
        path: 'quickGame',//快游戏门户
        loadChildren: './portal/quickGame/quickGame.module#QuickGameModule',
      },
      {
        path: 'xrQuickApp',//XR快应门户
        loadChildren: './portal/xrQuickApp/xrQuickApp.module#XrQuickAppModule',//XR快应用展示模块
      },
      {
        path: 'quickAppShow',//快应用推广
        loadChildren: './quickAppShow/quickAppShow.module#QuickAppShowModule',
      },   
      {
        path: 'contactUs',//联系方式
        loadChildren: './contactUs/contactUs.module#ContactUsModule',
      },
      {
        path: 'course',//课程
        loadChildren: './course/course.module#CourseModule',
      },
      {
        path: 'account',//账户相关
        loadChildren: './account/account.module#AccountModule',
      },
    ]
  },
  {
    path: 'docCenter',//快应用帮助中心
    canActivate: [UserInfoGuard],//先去拿用户信息，canActivate 比 Resolve先执行 
    loadChildren: './docCenter/doc-center.module#DocCenterModule',
  },
  {
    path: 'quickGameDocCenter',//快游戏帮助中心
    canActivate: [UserInfoGuard],//先去拿用户信息，canActivate 比 Resolve先执行 
    loadChildren: './quickGameDocCenter/quick-game-doc-center.module#QuickGameDocCenterModule',
  },
  {
    path: 'myCenter',//开发者中心
    component: FeatureComponent,
    canActivate: [UserInfoGuard],//先去拿用户信息，canActivate 比 Resolve先执行 
    children: [
      {
        path: '',//开发者中心-默认
        loadChildren: './myCenter/myCenter.module#MyCenterModule',
        canActivate: [LoginGuard,AuthGuard,WhiteListGuard],
      },
      {
        path: 'thirdPartyService',//服务商
        loadChildren: './myCenter/thirdPartyService/thirdPartyService.module#ThirdPartyServiceModule',
        canActivate: [LoginGuard,AuthGuard,WhiteListGuard],
      },
    ],
  },
  {
    path: 'active',//活动
    canActivate: [UserInfoGuard],//先去拿用户信息，canActivate 比 Resolve先执行 
    loadChildren: './active/active.module#ActiveModule',
  },
  {
    path: 'documentCenter',//文档
    canActivate: [UserInfoGuard],//先去拿用户信息，canActivate 比 Resolve先执行 
    loadChildren: './documentCenter/documentCenter.module#DocumentCenterModule',
  },
  {
    path: 'commonProblem',//常见问题
    canActivate: [UserInfoGuard],//先去拿用户信息，canActivate 比 Resolve先执行 
    loadChildren: './commonProblem/commonProblem.module#CommonProblemModule',
  },
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    customCommonModule
  ],
  declarations: [
    FeatureComponent,
  ],
  exports: [
    CommonModule,
    FeatureComponent,
  ],
  providers: [
    
  ],
})
export class FeatureModule {
  constructor(
    private router: Router,
    private baiduAnalyticsService: BaiduAnalyticsService,
  ) {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        let allowUrl = [
          '/',
          '/myCenter',
          '/myCenter/quickAppCenter',
          '/myCenter/accountBind',
          '/myCenter/appManage/appMainProcess/createApp',
          '/touringSalon',
          '/quickGame'
        ];
        if (allowUrl.includes(event.url)) {
          this.baiduAnalyticsService.trackPageview(event.url);
        }
      });
  }
}
