import { Directive, HostListener, Input } from '@angular/core';
import { Validator, NgControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[isRepeatFzr]',
  providers: [{
    provide: NG_VALIDATORS, 
    useExisting: IsRepeatFzrDirective, 
    multi: true
  }],
})
export class IsRepeatFzrDirective implements Validator {
  @Input() id:string;
  @Input() dw: any;
  @Input() rpk: any;
  @Input() compareVuale:string;
  constructor() { } 
  validate(control): any {   
    // 主体(姓名+证件号) && 快应用(姓名+证件号) && 当前有值
    if(this.dw.fzrXm && this.rpk.fzrXm && this.dw.zjhm && this.rpk.fzrZjhm && control.value){
      let str1 = this.dw.fzrXm + this.dw.fzrZjhm;
      let str2 = this.rpk.fzrXm + this.rpk.fzrZjhm;
      // (姓名+证件号) 是否相等
      if (str1 == str2) {
        // 相等，则主办负责人、快应用负责人填写的手机号、应急手机号、邮箱必须一致；
        if(control.value == this.compareVuale ){
          return null
        }else{
          return {'isRepeat':'请与主办单位中填写的信息保持一致'};
        }
      }else{
        // 不等，则主办负责人、快应用负责人填写的手机号、应急手机号、邮箱必须不一致；
        if(control.value == this.compareVuale ){
          return {'isRepeat':'不能与主办单位负责人的信息一致，请重新填写'};
        }else{
          return null
        }
      }
    }else{
      return null
    }
  } 
  trim(str) {        
    return str.replace(/(^\s+)|(\s+$)/g, "")
  }
}
