import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-defaultFiling',
  templateUrl: './defaultFiling.component.html',
  styleUrls: ['./defaultFiling.component.scss']
})
export class DefaultFilingComponent implements OnInit {
  @Input() activeRouterModule;

  constructor(
    
  ) { }
  ngOnInit() {
  }
}
