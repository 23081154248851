import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { MemberService } from '../../../../services/member.service';
import { RedService } from '../../../../services/red.service'

@Component({
  selector: 'app-developerCenter',
  templateUrl: './developerCenter.component.html',
  styleUrls: ['./developerCenter.component.scss']
})
export class DeveloperCenterComponent implements OnInit {
  @Input() activeRouterModule;
  userInfo; //账号信息
  filter_member_huawei: Array<any> = [];//过滤出华为账户，用于提醒华为开发者同意华为开发者协议
  isAccountBind: string;
  redDotPrompt:any;

  constructor(
    public userService: UserService,
    private memberService: MemberService,
    private redService: RedService,
    private platformLocation: PlatformLocation
  ) { }
  ngOnInit() {
    
    this.userInfo = this.userService.user;
    this.isAccountBind = this.platformLocation.pathname;//获取当前路径
    this.getIsRead();
    this.memberService.filterMembers('huawei').subscribe(members => {//过滤华为厂商：用于提醒旧华为开发者，显示红点，提醒去同意华为开发者协议
      if (members.length) {
        if (members[0].memberUserName != null && members[0].status == 1 && members[0].protocolStatus != '1') {
          this.isAccountBind = this.platformLocation.pathname;//获取当前路径：如果已在华为协议里不需要显示红点
          this.filter_member_huawei = members;
        }
      }
    });
  }
  goAccountBind(){
    if(this.redDotPrompt!=1){
      this.redService.read({"email":this.userInfo.email}).subscribe(data=>{

      })
    }
  }
  getIsRead(){
    this.redService.isRead({"email":this.userInfo.email}).subscribe(data=>{
      if(data["code"]==0){
        this.redDotPrompt= data["data"]["isRead"]
      }
      
    })
  }
}
