import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-appProgressBox',
  templateUrl: './appProgressBox.component.html',
  styleUrls: ['./appProgressBox.component.scss']
})
export class AppProgressBoxComponent implements OnInit {
  @Input() active = 0;//进度下标
  progressTip = [
    { proNum: '1', proTitle: '创建应用', },
    { proNum: '2', proTitle: '发布版本', },
    { proNum: '3', proTitle: '审核上线', },
  ];
  constructor() { }

  ngOnInit() {
    
  }

}
