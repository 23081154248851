import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gameProgressBox',
  templateUrl: './gameProgressBox.component.html',
  styleUrls: ['./gameProgressBox.component.scss']
})
export class GameProgressBoxComponent implements OnInit {
  @Input() active = 0;//进度下标
  progressTip = [
    { proNum: '1', proTitle: '创建游戏', },
    { proNum: '2', proTitle: '基本信息填写', },
    { proNum: '3', proTitle: '推广信息填写', },
    { proNum: '4', proTitle: '发布游戏', },
    { proNum: '5', proTitle: '等待审核', }
  ];
  constructor() { }

  ngOnInit() {
    
  }

}
