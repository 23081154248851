import { Injectable } from '@angular/core';
import { Observer } from 'rxjs/Observer';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

// const redirect_uri = `http%3A%2F%2Fhapjs.org%3A4200%2FmyCenter%2FaccountBind`;
@Injectable()
export class MemberService {
  public members: any[];
  public isMembers: any;
  private url: string;
  constructor(private httpClient: HttpClient) { }
  // 获取厂商列表(开发者中心进入前就会获取厂商列表,开发者中心模块可以直接拿到厂商list)
  getMembers(developerUuid: string): Observable<any[]> {
    return Observable.create((observer: Observer<any>) => {
      if(this.members){
        observer.next(this.members);
      }else{
        this.httpClient.get('/api/provider/queryMemberList', { params: { uuid: developerUuid } })
          .subscribe(data => {
            if (data['code'] === 0) {
              this.members = data['data']['dataList'];//厂商list
              this.isMembers = this.members.filter(member => { return member.status == 1 }).length > 0;//是否绑定一家厂商
              observer.next(this.members);
            } else {
              observer.error(data['code']);
            }
          });
      }
    });
  }
  //拼接url
  getRedirectUri() {
    let redirect_uri = location.protocol + '//' + location.hostname;
    if (location.port) {
      redirect_uri += ':' + location.port;
    }
    redirect_uri += '/myCenter/accountBind';
    return redirect_uri;
  }
  // 根据memberCode或者memberName获取厂商授权页面
  getMemberOauthUrlWithNameOrCode(nameOrCode: string): Observable<any> {
    const redirect_uri = encodeURIComponent(this.getRedirectUri());
    return Observable.create((observer: Observer<any>) => {
      this.httpClient.get('/api/member/queryMemberList')
        .subscribe(data => {
          if (data['code'] === 0) {
            this.members = data['data'];
            for (let mem of this.members) {
              if (mem.memberName === nameOrCode || mem.memberCode === nameOrCode) {
                this.url = `${mem.oauthUrl}&redirect_uri=${redirect_uri}&state=${mem.memberCode}`;
                observer.next(this.url);
              }
            }
          } else {
            observer.error(data['code']);
          }
        });
    });
  }
  // 过滤某一家厂商
  filterMembers(memberCode: string): Observable<any[]> {
    return Observable.create((observer: Observer<any>) => {
      if (this.members) {
        let filter_members = this.members.filter((member) => {
          if (typeof memberCode == 'string') {
            return memberCode.indexOf(member.memberCode) != -1
          }
        })
        observer.next(filter_members);
      } else {
        observer.next([]);
      }
    });
  }
  // 过滤某一家厂商，并绑定了厂商成功
  inFilterMembers(memberCode: string): Observable<any[]> {
    return Observable.create((observer: Observer<any>) => {
      if (this.members) {
        let filter_members = this.members.filter((member) => {
          return memberCode.indexOf(member.memberCode) != -1 && member.status == 1 //绑定了
        })
        observer.next(filter_members);
      } else {
        observer.next([]);
      }
    });
  }
  //在厂商列表里（传入开发者uuid）
  developerInMembers(developerUuid: string): Observable<any[]> {
    return Observable.create((observer: Observer<any>) => {
      this.httpClient.get('/api/provider/queryMemberList', {
        params: { uuid: developerUuid }
      }).subscribe(data => {
        if (data['code'] === 0) {
          let filter_member = data['data']['dataList'].filter((member) => {
            return member.status == 1;
          })
          observer.next(filter_member);
        } else {
          observer.error(data['code']);
        }
      });
    });
  }
}
