import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import qs from 'qs';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Location } from '@angular/common';
import { environment } from '@/environments/environment';
import { throttle } from '@/app/core/utils/util';
import { DialogService } from '@/app/core/template/dialog/dialog.service';
@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  dialogSwitch=false;
  constructor(
    private modalService: NgbModal,
    private dialogService: DialogService,
  ) { }
  getVersionFile(){
    console.log(environment.version)
    if(!environment.version)return;
    this.dialogSwitch = true
    $.ajax({
      url: `/assets/version/version.json?timestamp=${new Date().getTime()}`,
      type: "GET",
      data: {},
      dataType: 'json',
      success: (res)=>{
        console.log(res)
        setTimeout(()=>{
          if(res['version'] == environment.version){
            // 无版本变化
          }else{
            // 有版本变化
            this.dialogService.info({
              message: '检测到服务器新版本',
              closeButton:false,
              cancelButton:false,
              cofirmButtonText: '更新',
              onConfirm:()=>{
                location.reload(true);
              }
            });
          }
        })
      },
      error:(error)=>{
        console.log(error)
      },	
      complete:()=>{
        this.dialogSwitch = false
      },
    });
  }
  throttleFunction = throttle(this.getVersionFile, 10000);
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 缓存检测：方案1（接口前检查是否存在版本差异并及时跟新）
    // !this.dialogSwitch && this.throttleFunction();


    // 请求消息拦截处理
    let newReq = req;
    if (req.method.toLowerCase() === 'post') {
      newReq = req.clone({
        body: qs.stringify(req.body),
        setHeaders: {
          "Content-Type":"application/x-www-form-urlencoded",
          "charSet":"UTF-8"
        },
      });
    }
    return next.handle(newReq)
      // 响应消息拦截处理
      .do(event => {
        const res = event as HttpResponse<any>;
        if (res.body && (res.body.code === 10005 || res.body.code === 10202) && !newReq.url.endsWith('queryAccountInfo')) {
          // const url = this.router.routerState.snapshot.url;
          // console.log(url);
          // this.router.navigate([url]);
          // location.reload();
        }
      });
  }
}
