import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@/app/core/services/user.service';

@Component({
  selector: 'app-defaultHome',
  templateUrl: './defaultHome.component.html',
  styleUrls: ['./defaultHome.component.scss']
})
export class DefaultHomeComponent implements OnInit {
  @Input() activeRouterModule
  userInfo: any; //账号信息
  constructor(
    public userService: UserService
  ) { }

  ngOnInit() {
    this.userInfo = this.userService.user
  }
}
