
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';



const routes = [
  {
    path: '',
    loadChildren: './feature/feature.module#FeatureModule',//懒加载模块：当路由匹配上是 去加载模块内容
  },
];

@NgModule({
  imports: [
    // 路由
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
  providers: [],
})
export class AppRoutingModule {}
