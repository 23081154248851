import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-goBack',
  templateUrl: './goBack.component.html',
  styleUrls: ['./goBack.component.scss']
})
export class GoBackDlgComponent implements OnInit {
  options={
    title: '提示',
    message: '',
    danger: '',
    onConfirm: () => {},
    onCancel: () => {},
    closeButton: true,
    cofirmButton: true,
    cancelButton: true,
    cofirmButtonText: '立即离开',
    cancelButtonText: '继续编辑',
    textX: '',
  };
  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {

  }
  init(options) {
    Object.assign(this.options,options)
  }
  confirm() {
    this.activeModal.close();
    this.options.onConfirm();
  }
  cancel() {
    this.activeModal.close();
    this.options.onCancel();
  }
}
