import {Directive,HostListener} from '@angular/core';
import {  NgControl } from '@angular/forms';
@Directive({
  selector: '[allTrim]'
})
export class AllTrimDirective {
  constructor(private control:NgControl) { }
  @HostListener("blur", ["$event.target"])    
  keyupFun(target) {     
    if (target.value) {            
      this.control.control.setValue(this.allTrim(target.value));
    }
  } 
  allTrim(str) {        
    return str.replace(/\s+/g,"");
  }
}
