import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-crumbs',
  templateUrl: './crumbs.component.html',
  styleUrls: ['./crumbs.component.scss']
})
export class CrumbsComponent implements OnInit {
  @Input()
  crumbsList:Array<any> = [];
  constructor() { }

  ngOnInit() {
  }

}
