import { Directive, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
@Directive({
  selector: '[disabledForm]',
})
export class DisabledFormDirective {
  @Input() disabled:boolean;
  @Input() update:any;
  constructor(private ngForm: NgForm) { }
  ngOnChanges(changes) {
    if(this.disabled){
      setTimeout(()=>{
        this.ngForm.form.disable(); // 禁用
      },500)
    }else{
      setTimeout(()=>{
        this.ngForm.form.enable(); // 解除禁用
      },500)
    }
  }
}
