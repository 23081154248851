import {Directive,HostListener} from '@angular/core';
import * as $ from "jquery";
@Directive({
  selector: '[lookImage]'
})
export class LookImageDirective {
  constructor(
    
  ) { }
  @HostListener("click", ["$event.target"])    
  clickFun(target) {    
    // this.fnCreate(target.getAttribute('src'))
    this.imgShow(target.getAttribute('src'))
  } 
  imgShow(url){  
    let div = $(`
          <div 
            id="lookImageBox" 
            style="
              position:fixed;
              top:0;
              left:0;
              background:rgba(0,0,0,0.5);
              z-index:2;
              width:100%;
              height:100%;
              display: flex;
              flex-wrap: wrap;
              box-sizing: nowrap;
              justify-content: center;
              align-items: center;
              overflow: scroll;
            ">
              <div style="display: flex;height:500px;">
                <div style="width:400px;"></div>
                <div style="position: relative;">
                  <div style="height:100px;"></div>
                  <img id="close" src="/assets/images/common/close.png" style="position: absolute;right: 0px;top:50px;cursor: pointer;"/>
                  <img src="${url}" width="80%"/>
                  <div style="height:100px;"></div>
                </div>
                <div style="width:200px;"></div>
              </div>
          </div>`); 
    $('body').append(div);
    $('#close').click(function(){//再次点击淡出消失弹出层  
        $('#lookImageBox').remove();  
    });  
  } 


  fnCreate(src) {

    /* 要创建的div的classname */
    var ClassName = "thumbviewbox" ;

    if (src == '' ) {
        return false ;
    }

   /* 优先创建图片，如果图片没有加载成功，回调自己 */
    var img = document.createElement('img' );
    img.setAttribute( 'src', src);
 //显示图片的大小
    var imgwd = 300 ;
    var imghg = 300 ;

    if (imgwd < 1) {
        var timer = setTimeout("fnCreate('" + src + "')", 100);
        return false ;
    } else {
        clearInterval(timer);
    }

    /* 清除已经弹出的窗口，防止冒泡 */
    var em = document.getElementsByClassName(ClassName)

    for (var i = em.length - 1; i >= 0; i--) {
        var p = em[i];
        p.parentNode.removeChild(p);
    }

   /* 各项参数 */
    var htmlWidth = window.innerWidth;             //可见区域宽度
    var htmlHeight = window.innerHeight;           //可见区域高度
    var divleft = 0;                                                 //将要创建的div的左边距
    var divtop = 0;                                                //将要创建的div的右边距
    var closefunction = 'em=document.getElementsByClassName("' + ClassName + '");for(var i=em.length-1;i>=0;i--){var p=em[i];p.parentNode.removeChild(p);}';     //关闭div的代码

    img.setAttribute( 'onclick', closefunction);

   /* 计算通过图片计算div应该在的位置，保证弹窗在页面中央 */
    if (imgwd > htmlWidth * 0.8) {
        img.setAttribute( 'width', (htmlWidth * 0.8).toString());
        divleft = htmlWidth * 0.1;
        if (imghg > htmlHeight * 0.8) {
            divtop = htmlHeight * 0.1;
        } else {
            divtop = (htmlHeight - imgwd) / 2;
        }
    } else {
        img.setAttribute( 'width', imgwd.toString());
        divleft = (htmlWidth - imgwd) / 2;
        if (imghg > htmlHeight * 0.8) {
            divtop = htmlHeight * 0.1;
        }
        else {
            divtop = (htmlHeight - imgwd) / 2;
        }
    }

    /* 创建关闭按钮 */
    var closebtn = document.createElement('a');
    closebtn.setAttribute( 'class', 'close' );
    closebtn.setAttribute( 'href', 'javascript:;' );
    closebtn.setAttribute( 'style', 'position:absolute;top:-20px;right:-5px;cursor:pointer;background:#444;color:#FFF;' );
    closebtn.innerHTML = '[close/关?闭??]';
    closebtn.setAttribute( 'onclick', closefunction);

    /* 创建弹窗 */
    var element = document.createElement('div');
    element.appendChild(img);
    element.appendChild(closebtn);
    element.setAttribute( 'class', ClassName);
    element.setAttribute( 'style', 'border:5px solid #444;position:absolute;top:' + Math.round(divtop) + 'px;left:' + Math.round(divleft) + 'px;border-radius:5px;background:#FFF;z-index:9999;' );
    document.body.appendChild(element);
  }
}
