import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { MemberService } from '../services/member.service';
import { DialogService } from '../template/dialog/dialog.service';

@Injectable()
export class MembersGuard implements /*CanLoad,*/ CanActivate {

  constructor(
    private memberService: MemberService,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return Observable.create((observer: Observer<boolean>) => {
        //去后台拿绑定厂商信息
        this.memberService.getMembers('').subscribe(members => {
          let inMember = members.some(member => member.status == 1);//只要有一家厂商通过，那就放行
          if (inMember) {
            observer.next(true);
          } else {
            observer.next(false);
            this.dialogService.info({
              message: '您还未绑定厂商开发者账号，请先绑定厂商开发者账号',
              onConfirm: () => {
                this.router.navigate(['/myCenter/accountBind/bindGuard']);
              }
            });
          }
        });
      });
  }
}
