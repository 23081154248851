/*
    时间对象格式化
    new Date()
    'yyyy/MM/dd hh:mm:ss'
*/
export function format(Date, format='') {
  const date = {
    'M+': Date.getMonth() + 1,
    'd+': Date.getDate(),
    'h+': Date.getHours(),
    'm+': Date.getMinutes(),
    's+': Date.getSeconds(),
    'q+': Math.floor((Date.getMonth() + 3) / 3),
    'S+': Date.getMilliseconds(),
  }
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (Date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (var k in date) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? date[k]
          : ('00' + date[k]).substr(('' + date[k]).length)
      )
    }
  }
  return format
}
/*
* 深拷贝
* */
function copyObj (obj = {}) {           
    let newobj = null;  
    //判断是否需要继续进行递归
    if (typeof (obj) == 'object' && obj !== null) {
        newobj = obj instanceof Array ? [] : {};                //进行下一层递归克隆
        for (var i in obj) {
            newobj[i] = copyObj(obj[i])
        }                //如果不是对象直接赋值
    } else newobj = obj;            
    return newobj;    
}
/*
* 浅拷贝
* */
export function copyObj2(obj = {}){
  return JSON.parse(JSON.stringify(obj));
}
/*
* 清楚字符串空格
* */
export function Trim(str) {
  var result;
  result = str.replace(/(^\s+)|(\s+$)/g, "");
  return result;
}

/*
* 清空数组空数据
* */
export function clearListEmpty(arr: any[] = []): any {
  let arr2 = arr.filter(item=>!isEmpty(item))
  return arr2;
}
/*
* 判断数组是否有重复项
* */
export function isRepeat(arr) {
  let hash = {};
  for (let i in arr) {
    if (hash[arr[i]])
      return true;
    hash[arr[i]] = true;
  }
  return false;
}

/*
* 判断关键字数组对象是否有重复项
* */
export function aryObjIsRepeat(arr, index) {
  return arr.slice(0, index).some(item => item.name == arr[index].name);
}

/*
* 判断是否为空
* */
export function isEmpty (val) { 
  // null or undefined
  if (val == null) return true;

  if (typeof val === 'boolean') return false;

  if (typeof val === 'number') return !val;

  if (val instanceof Error) return val.message === '';

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length;

    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !val.size;
    }
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length;
    }
  }
  return false;
}

//记录字符的字节数
export function getCharLength(str='') {
  if(isEmpty(str)){
    return 
  }   
  let iLength = 0;  //记录字符的字节数
  for (let i = 0; i < str.length; i++)  //遍历字符串中的每个字符
  {
    if (str.charCodeAt(i) > 255)   //如果当前字符的编码大于255
    {
      iLength += 2;    //所占字节数加2
    }
    else {
      iLength += 1;   //否则所占字节数加1
    }
  }
  return iLength;   //返回字符所占字节数
}

//加载js
export function loadScript(scriptUrl: string) {
  return new Promise((resolve, reject) => {
    const scriptElement = document.createElement('script')
    scriptElement.src = scriptUrl
    scriptElement.onload = resolve
    document.body.appendChild(scriptElement)
  })
}
//节流
export function throttle(func, delay) { 
  let lastCallTime = 0;
  return function (...args):void {
    const now = new Date().getTime();

    if (now - lastCallTime >= delay) {
      func.apply(this, args);
      lastCallTime = now;
    }
  };
}

//防抖
export function debounce(func, delay) {
  let timer;

  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}