import { Observable } from 'rxjs/Observable';
import { debounceTime, switchMap, map, first } from 'rxjs/operators';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Directive({
  selector: '[isIdCard]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IsIdCardDirective, multi: true }],
})
export class IsIdCardDirective implements Validator {
  constructor(
    private httpClient: HttpClient,
  ) { }
  validate(control: AbstractControl): any {
    if (control.value) {
      return this.checkID(control.value) ? null : { isIdCard: '身份证验证输入有误' };
    }
  }
  //身份证规则校验
  checkID(val) {
    if (this.checkCode(val)) {
      var date = val.substring(6, 14);
      if (this.checkDate(date)) {
        return true;
      }
    }
    return false;
  }
  //出生日期码校验
  checkDate(val) {
    var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
    if (pattern.test(val)) {
      var year = val.substring(0, 4);
      var month = val.substring(4, 6);
      var date = val.substring(6, 8);
      var date2 = new Date(year + "-" + month + "-" + date);
      if (date2 && date2.getMonth() == (parseInt(month) - 1)) {
        return true;
      }
    }
    return false;
  }
  //校验码校验
  checkCode(val) {
    var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
    var code = val.substring(17);
    if (p.test(val)) {
      var sum = 0;
      for (var i = 0; i < 17; i++) {
        sum += val[i] * factor[i];
      }
      if (parity[sum % 11] == code.toUpperCase()) {
        return true;
      }
    }
    return false;
  }
}