import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filingProgressBox',
  templateUrl: './filingProgressBox.component.html',
  styleUrls: ['./filingProgressBox.component.scss']
})
export class FilingProgressBoxComponent implements OnInit {
  @Input() active = 0;//进度下标
  @Input()//进度数组
  progressTip = [
    { proNum: '1', proTitle: '主办单位信息', },
    { proNum: '2', proTitle: '快应用信息', },
    { proNum: '3', proTitle: '备案平台', },
    { proNum: '4', proTitle: '上传资料', },
    { proNum: '5', proTitle: '提交管局', }
  ];
  constructor() { }

  ngOnInit() {
    
  }

}
