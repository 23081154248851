import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@/app/core/services/user.service';

@Component({
  selector: 'app-defaultXrQuickApp',
  templateUrl: './defaultXrQuickApp.component.html',
  styleUrls: ['./defaultXrQuickApp.component.scss']
})
export class DefaultXrQuickAppComponent implements OnInit {
  userInfo
  @Input() activeRouterModule
  constructor(
    public userService: UserService
  ) { }

  ngOnInit() {
    this.userInfo = this.userService.user
  }

}
