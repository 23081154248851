import { Directive, HostListener, Input } from '@angular/core';
import { Validator, NgControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[isEqual]',
  providers: [{
    provide: NG_VALIDATORS, 
    useExisting: IsEqualDirective, 
    multi: true
  }],
})
export class IsEqualDirective implements Validator {
  @Input() id:string;
  @Input() compareValue1: string;
  @Input() compareValue2: string;
  constructor() { } 
  validate(control): any {     
    if (this.compareValue1 && this.compareValue2 ) {   
      if(this.compareValue1 == this.compareValue2){
        control['id'] = this.id;//用于滚动到具体位置
        return {'isEqual':true};
      }else{
        return null
      }
    }else{
      return null
    }
  } 
  trim(str) {        
    return str.replace(/(^\s+)|(\s+$)/g, "")
  }
}
