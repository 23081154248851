import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingDlgComponent {

  options:any={};
  constructor() { }

  ngOnInit() {
  }
  init(options) {
    Object.assign(this.options,options)
  }

}
