import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'form-view',
  templateUrl: './formView.component.html',
  styleUrls: ['./formView.component.scss'],
  encapsulation: ViewEncapsulation.None,//作用于覆盖视图里的样式
})
export class FormViewComponent implements OnInit {
  @Input() title;//标签title
  constructor() { }

  ngOnInit() {
  }


}
