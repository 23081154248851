import { LoginGuard } from './guard/loginGuard.service';
import { AuthGuard } from './guard/authGuard.service';
import { WhiteListGuard } from './guard/whiteListGuard.service';
import { BaiduAnalyticsService } from './services/baiduAnalytics.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyHttpInterceptor } from './interceptor/http.interceptor';
import { UserService } from './services/user.service';
import { MemberService } from './services/member.service';
import { RedService } from './services/red.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserInfoGuard } from './guard/userInfoGuard.service';
import { DialogModule } from './template/dialog/dialog.module';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { MembersGuard } from './guard/membersGuard.service';
/*注意定义了Module,Severce，Guard，公共起来*/
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgbModule,
    DialogModule,
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    //服务
    UserService,
    MemberService,
    BaiduAnalyticsService,
    CookieService,
    RedService,
    //守卫
    LoginGuard,
    AuthGuard,
    WhiteListGuard,
    UserInfoGuard,
    MembersGuard,
  ],
})
export class CoreModule {

  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

}
