
import { Validator, FormControl, FormGroup, NG_VALIDATORS } from '@angular/forms';
import { Directive, EventEmitter, Input } from '@angular/core';
@Directive({
    selector:'[addressLength]',
    providers: [{
      provide: NG_VALIDATORS, 
      useExisting: AddressLengthDirective, 
      multi: true
    }],
})
export class AddressLengthDirective implements Validator{
  @Input() id:string;
  constructor(
     
  ) { }
    validate(group:FormGroup) {
      //全部验证通过 group就算通过
      let arr = [];
      for (let i in group.controls) {
        if(group.controls[i].value){
          arr.push(i)
        }
      }
      if (arr.length == 3) {
        return null;
      }else{
        if(this.id){
          group['id']=this.id;//用于滚动到具体位置
        }
        return {'required':true};
      }
    }
}