import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ICP',
  templateUrl: './ICP.component.html',
  styleUrls: ['./ICP.component.scss']
})
export class ICPComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
