import { Component } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoDlgComponent {
    options={
      size:'small',// small | medium
      title: '提示',
      message: '',
      onConfirm: () => {},
      onCancel: () => {},
      closeButton: true,
      cofirmButton: true,
      cancelButton: false,
      cofirmButtonText: '确定',
      cancelButtonText: '',
      textX: '',
    };
    constructor(public activeModal: NgbActiveModal) {}

    init(options) {
      Object.assign(this.options,options)
    }
    confirm() {
      this.activeModal.close();
      this.options.onConfirm();
    }
    cancel() {
      this.activeModal.close();
      this.options.onCancel();
    }
    closeModal() {
      this.activeModal.close();
    }
}
