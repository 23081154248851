
import { Validator, FormControl, FormGroup, NG_VALIDATORS } from '@angular/forms';
import { Directive, EventEmitter, Input } from '@angular/core';
@Directive({
    selector:'[servicesObject]',
    providers: [{
      provide: NG_VALIDATORS, 
      useExisting: ServicesObjectDirective, 
      multi: true
    }],
})
export class ServicesObjectDirective implements Validator{
  @Input() count:number;
  @Input() id:string;
  @Input() servicesObject:any = {};
  @Input() key:string;
  constructor() { }
  validate(group:FormGroup) {
    let arr = [];
    for(let name in this.servicesObject){
      let item = this.servicesObject[name];
      let childrenFilter = item.children.filter(item=>item[this.key]);
      arr = arr.concat(childrenFilter)
    }
    if (arr.length<=5) {
      return null;
    }else{
      group['id'] = this.id;//用于滚动到具体位置
      return {'isImg':false};
    }
  }
}