import { Observable } from 'rxjs/Observable';
import { Validator, AbstractControl, FormGroup, NG_VALIDATORS } from '@angular/forms';
import { Directive, EventEmitter, Input } from '@angular/core';
@Directive({
  selector:'[idCardDate]',
  providers: [{
    provide: NG_VALIDATORS, 
    useExisting: IdCardDateDirective, 
    multi: true
  }],
})
export class IdCardDateDirective implements Validator {
  @Input() isRequired:any;
  @Input() startTime:any;
  @Input() endTime:any;
  @Input() id:string;
  constructor() { }
  validate(group:FormGroup) {
    if(this.isRequired){
      if(this.startTime){
        if (this.endTime) {
          if(this.startTime < this.endTime){
            return null;
          }else{
            group['id'] = this.id;//用于滚动到具体位置
            return {'is':false};
          }
        }else{
          return null;
        }
      }else{
        group['id'] = this.id;//用于滚动到具体位置
        return {'is':false};
      }
    }else{
      return null;
    }
  }
}