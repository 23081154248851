import { Injectable } from '@angular/core';
import { CanActivate, Router, } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { UserService } from '../services/user.service';
import { DialogService } from '../../core/template/dialog/dialog.service';

@Injectable()
export class LoginGuard implements /*CanLoad,*/ CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
      return Observable.create((observer: Observer<boolean>) => {
        //去后台拿登录信息
          if (this.userService.user) {// 已经登录
            observer.next(true);
          } else { // 未登录
            let backUrl=window.location.href;
            observer.next(false);
            this.dialogService.info({
              message: '用户未登录',
              onConfirm: () => {
                this.router.navigate(['/account/login'], { queryParams: { back: backUrl } });
              }
            });
          }
      });
  }
}
