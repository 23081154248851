import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertDlgComponent {
  options:any={
    size:'small',// small | medium
    confirmButtonText:'确定'
  };
  constructor(public activeModal: NgbActiveModal) {}

  init(options) {
    Object.assign(this.options,options)
  }
  confirm() {
    this.activeModal.close();
    this.options.onConfirm();
  }
}
