import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[strTrim]'
})
export class TrimDirective {
  constructor(private control:NgControl) { }
  @HostListener("blur", ["$event.target"])    
  keyupFun(target) {      
    if (target.value) {            
      this.control.control.setValue(this.trim(target.value));
    }
  } 
  trim(str) {        
    return str.replace(/(^\s+)|(\s+$)/g, "")
  }
}
