import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-awardsAct2Nav',
  templateUrl: './awardsAct2Nav.component.html',
  styleUrls: ['./awardsAct2Nav.component.scss']
})
export class AwardsAct2NavComponent implements OnInit {
  @Input() activeRouterModule;//选中的模块
  @Input() awardsActInfo;//风锐奖详情
  brotherNodeDisabled=false;
  newDate=new Date().getTime();
  constructor() { }

  ngOnInit() { 
 
  }

}
