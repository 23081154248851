
import { Validator, FormControl, FormGroup, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input } from '@angular/core';
@Directive({
    selector:'[imgLength2]',
    providers: [{
      provide: NG_VALIDATORS, 
      useExisting: ImgLength2Directive, 
      multi: true
    }],
})
export class ImgLength2Directive implements Validator{
  @Input() count:number;
  @Input() id:string;
  @Input() imgList:any[] = [];
  @Input() imgKey:string;
  constructor() { }
  validate(group:FormGroup) {
    if(this.imgList){
      let arr = this.imgList.filter(item=>item[this.imgKey]);
      if (this.imgKey && arr.length >= this.count) {
        return null;
      }else{
        group['id'] = this.id;//用于滚动到具体位置
        return {'isInvalid':true};
      }
    }{
      return null;
    }
  }
}