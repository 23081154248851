
import { Validator, FormControl, FormGroup, NG_VALIDATORS } from '@angular/forms';
import { Directive, EventEmitter, Input } from '@angular/core';
@Directive({
    selector:'[checkboxLength]',
    providers: [{
      provide: NG_VALIDATORS, 
      useExisting: CheckboxLengthDirective, 
      multi: true
    }],
})
export class CheckboxLengthDirective implements Validator{
  @Input() id:string;
  @Input() count:number = 0;
  constructor(
     
  ) { }
    validate(group:FormGroup) {
      //只有一个验证通过 group就算通过
      let arr = [];
      for (let i in group.controls) {
        if(group.controls[i].value){
          arr.push(i)
        }
      }
      if (arr.length) {
        if(this.count){
          if(arr.length<=this.count){
            return null;
          }else{
            if(this.id){
              group['id']=this.id;//用于滚动到具体位置
            }
            return {'checkboxLength':this.count};
          }
        }else {
          return null;
        }
      }else{
        if(this.id){
          group['id']=this.id;//用于滚动到具体位置
        }
        return {'checkboxLength':0};
      }
    }
}