import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@/app/core/services/user.service';
@Component({
  selector: 'app-documentCenterHeader',
  templateUrl: './documentCenterHeader.component.html',
  styleUrls: ['./documentCenterHeader.component.scss']
})
export class DocumentCenterHeaderComponent implements OnInit {
  userInfo
  @Input() activeRouterModule
  constructor(
    public userService: UserService
  ) { }

  ngOnInit() {
    this.userInfo = this.userService.user
  }

}
