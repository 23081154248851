import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-delay',
  templateUrl: './delay.component.html',
  styleUrls: ['./delay.component.scss']
})
export class DelayDlgComponent{
  timer=null;
  options={
    title: '',
    size:'small',// small | medium
    message: '',
    cofirmButtonText: '确定',
    delayNumber: 3,
    closeButton: false,
    keyboard:false, // 按esc键盘不关闭
    onConfirm: () => {},
  };
  constructor(public activeModal: NgbActiveModal) {}

  init(options) {
    Object.assign(this.options,options);
    this.timer = setInterval(()=>{
      this.options.delayNumber--;
      if(this.options.delayNumber<=0) {
        this.activeModal.close();
        this.options.onConfirm();
        clearInterval(this.timer);
      }
    },1000)
  }
  confirm() {
    clearInterval(this.timer);
    this.activeModal.close();
    this.options.onConfirm();
  }
}
