import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@/app/core/services/user.service';

@Component({
  selector: 'app-defaultQuickGame',
  templateUrl: './defaultQuickGame.component.html',
  styleUrls: ['./defaultQuickGame.component.scss']
})
export class DefaultQuickGameComponent implements OnInit {
  @Input() activeRouterModule
  userInfo; //账号信息
  constructor(
    public userService: UserService
  ) { }
  ngOnInit() {
    this.userInfo = this.userService.user
  }
}
