import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentCenterICP',
  templateUrl: './documentCenterICP.component.html',
  styleUrls: ['./documentCenterICP.component.scss']
})
export class DocumentCenterICPComponent implements OnInit {
  fullYear=new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
