import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Directive({
    selector:'[inCaptcha]',
    providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: CaptchaAsyncDirective, multi: true}]
})
export class CaptchaAsyncDirective implements AsyncValidator{
  @Input() inCaptchaAPI: string;
  @Input() inCaptchaType: string;
   constructor(
    private httpClient: HttpClient, 
  ) { }
    validate(control:AbstractControl) {
        return new Promise((resolve, reject) => {
          this.httpClient.post(this.inCaptchaAPI,{
            type:this.inCaptchaType,
            verificationCode:control.value
          }).subscribe(res => {
            if (res && res['code'] == 0) {
              resolve(null);
            }else{
              resolve({
                  'inCaptcha':'您输入的验证码有误'
              });
            }
          });
        });
    }
}