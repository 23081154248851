import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private httpClient: HttpClient
  ) { }
  //获取消息
  queryMessageList(params) {
    return this.httpClient.get('/api/paccount/message/queryList', { params: params });
  }
  //批量阅读
  batchReadMessage(params) {
    return this.httpClient.post('/api/paccount/message/batchRead', params);
  }
  //批量删除
  batchDeleteMessage(params) {
    return this.httpClient.post('/api/paccount/message/batchDelete', params);
  }
  //消息详情
  messageInfo(params) {
    return this.httpClient.get('/api/paccount/message/notifyDetail', { params: params });
  }
  //获取未读消息
  queryUnReadCnt() {
    return this.httpClient.post('/api/paccount/message/queryUnReadCnt', {});
  }

   // 公告列表
   getNoticeList(params){
    return this.httpClient.get('/api/p/notice/queryNotice', { params: params });
  }
}
