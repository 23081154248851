import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()

export class RedService{

  constructor(private httpClient: HttpClient) { }
  // 红点已读
  read(params){
    return this.httpClient.post('/api/p/notice/read',  params);
  }

  //是否显示红点
  isRead(params) {
  return this.httpClient.post('/api/p/notice/isRead', params);
  }

}