import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@/app/core/services/user.service';
@Component({
  selector: 'app-flowSupportHeader',
  templateUrl: './flowSupportHeader.component.html',
  styleUrls: ['./flowSupportHeader.component.scss']
})
export class FlowSupportHeaderComponent implements OnInit {
  userInfo
  @Input() activeRouterModule
  constructor(
    public userService: UserService
  ) { }

  ngOnInit() {
    this.userInfo = this.userService.user;
  }
}
