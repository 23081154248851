import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-defaultICP',
  templateUrl: './defaultICP.component.html',
  styleUrls: ['./defaultICP.component.scss']
})
export class DefaultICPComponent implements OnInit {
  fullYear=new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
