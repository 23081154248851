import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Injectable } from '@angular/core';

@Injectable()
export class BaiduAnalyticsService {
    trackPageview(pageUrl: string){
      _hmt.push(['_trackPageview', pageUrl]);
    }

    trackEvent(
      category: string,
      action: string,
      opt_label: string="-",
      opt_value?: number
    ) {
      if (opt_value) {
        _hmt.push(['_trackEvent', category, action, opt_label, opt_value]);
      } else {
        _hmt.push(['_trackEvent', category, action, opt_label]);
      }
    }
}
