
import { Validator, FormControl, FormGroup, NG_VALIDATORS } from '@angular/forms';
import { Directive, EventEmitter, Input } from '@angular/core';
@Directive({
    selector:'[isRepeat]',
    providers: [{
      provide: NG_VALIDATORS, 
      useExisting: IsRepeatDirective, 
      multi: true
    }],
})
export class IsRepeatDirective implements Validator{
  @Input() id:string;
  @Input() list:Array<any> =[];
  constructor(
     
  ) { }
    validate(group:FormGroup) {
      var hash = {}; 
      for (let i in group.controls) {
        if(group.controls[i].value){
          if(hash[group.controls[i].value]) return {'isRepeat':true}; 
          hash[group.controls[i].value] = true; 
        }
      }
      return null;
    }
}