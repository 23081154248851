import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'form-content',
  templateUrl: './formContent.component.html',
  styleUrls: ['./formContent.component.scss']
})
export class FormContentComponent implements OnInit {
  @Input() title;//标签title
  constructor() { }

  ngOnInit() {
  }

}
