import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-awardsActHeader',
  templateUrl: './awardsActHeader.component.html',
  styleUrls: ['./awardsActHeader.component.scss']
})
export class AwardsActHeaderComponent implements OnInit {
  @Input() activeRouterModule;//选中的模块
  @Input() awardsActInfo;//风锐奖详情
  brotherNodeDisabled=false;
  newDate=new Date().getTime();
  constructor(
    
  ) { }

  ngOnInit() {
  }
}
